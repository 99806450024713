@charset "UTF-8";
@import "~@flaticon/flaticon-uicons/css/all/all";

/*-----------------------------------------------------------------------------------

    Template Name: Airvice - AC Repair Services HTML5 Template
    Description: Airvice - AC Repair Services HTML5 Template
    Author: Theme_Pure
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
    02. Header CSS
    03. Hero CSS
    04. Cta CSS
    05. About CSS
    06. Fact CSS
    07. Service CSS
    08. Contact CSS
    09. Team CSS
    10. Portfolio CSS
    11. Choose CSS
    12. Price CSS
    13. Testimonial CSS
    14. Blog CSS
    15. Footer CSS
    16. Video CSS
    17. Faq CSS
    18. Gallery CSS
    19. Work CSS
    20. Feature CSS
    21. Brand CSS
    22. Page Title CSS
    22. Skill CSS

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
body {
  font-family: "Saira Semi Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #4f5168;
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

.custom-container {
  max-width: 1530px;
  min-width: 1530px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .custom-container {
    max-width: 1400px;
    min-width: 1400px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .custom-container {
    max-width: 100%;
    min-width: 100%;
  }
}

.img,
img {
  max-width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

ul li a {
  list-style: none !important;
  text-decoration: none !important;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.fix {
  overflow: hidden;
}

a,
button,
i {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: medium none;
  text-decoration: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input,
select,
textarea {
  font-family: "Saira Semi Condensed", sans-serif;
  font-weight: 500;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Saira Semi Condensed", sans-serif;
  color: #151735;
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  text-transform: normal;
  margin-bottom: 10px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #4f5168;
  margin-bottom: 10px;
}

hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  color: #1d284b;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.display-inline {
  display: inline-block;
}

*::-moz-selection {
  background: #222;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #222;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #222;
  color: #ffffff;
  text-shadow: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input,
select {
  font-family: "Saira Semi Condensed", sans-serif;
  font-weight: 500;
}

.custom-pad-20 {
  padding-left: 10px;
  padding-right: 10px;
}

.custom-mar-20 {
  margin-right: -10px;
  margin-left: -10px;
}

/*--
    - Input Placeholder
-----------------------------------------*/
* input::-moz-placeholder {
  font-size: 16px;
  opacity: 1;
  font-family: "Saira Semi Condensed", sans-serif;
  font-weight: 500;
}

* input::placeholder {
  font-size: 16px;
  opacity: 1;
  font-family: "Saira Semi Condensed", sans-serif;
  font-weight: 500;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: #f3f6f9;
}

.grey-soft-bg {
  background: #f3f5f9;
}

.white-bg {
  background: #ffffff;
}

.black-bg {
  background: #222;
}

.black-soft-bg {
  background: #1d284b;
}

.theme-bg {
  background: #ff6600;
}

.theme-bg-blue {
  background: #2371ff;
}

/*--
    - color
-----------------------------------------*/
.white-color {
  color: #ffffff;
}

.black-color {
  color: #222;
}

.theme-color {
  color: #ff6600;
}

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

.subtitle {
  font-size: 20px;
  color: #ff6600;
  font-weight: 500;
}
.subtitle img {
  margin-right: 5px;
}

.subtitle-border {
  position: relative;
  display: inline-block;
}
.subtitle-border:before,
.subtitle-border:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 30px;
  background: #ff6600;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
}
.subtitle-border:after {
  left: auto;
  right: -40px;
}

.section-title {
  font-size: 60px;
  line-height: 1.08;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .section-title {
    font-size: 42px;
  }
  .section-title br {
    display: none;
  }
}
.section-title span {
  color: #ff6600;
}
.section-title.text-white span {
  color: #ff6600 !important;
}

.theme-btn {
  font-size: 16px;
  color: #ffffff;
  height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 0px 45px;
  border-radius: 50px;
  display: inline-block;
  background: #ff6600;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.theme-btn:before {
  background-color: #fff;
  content: "";
  height: 150px;
  left: -75px;
  position: absolute;
  top: -35px;
  transform: rotate(35deg);
  transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 40px;
  opacity: 0;
}
.theme-btn.theme-btn-white {
  background: #ffffff;
  color: #151735;
}
.theme-btn.theme-btn-white:hover {
  background: #ff6600;
  color: #ffffff;
}
.theme-btn.theme-btn-blue {
  background: #2371ff;
  color: #ffffff;
}
.theme-btn.theme-btn-blue:hover {
  background: #ff6600;
  color: #ffffff;
}
.theme-btn.grey-btn {
  background: #f7f7f7;
  border: 1px solid #ccc;
  color: #151735;
  line-height: 58px;
}
.theme-btn.grey-btn:hover {
  color: #ffffff;
  background: #ff6600;
  border-color: #ff6600;
}
.theme-btn.black-btn {
  background: #151735;
  color: #ffffff;
}
.theme-btn.black-btn:hover {
  color: #ff6600;
  background: #ffffff;
}
.theme-btn.gradient-btn {
  background-image: -moz-linear-gradient(20deg, #f63761 0%, #ff8e29 100%);
  background-image: -webkit-linear-gradient(20deg, #f63761 0%, #ff8e29 100%);
  background-image: -ms-linear-gradient(20deg, #f63761 0%, #ff8e29 100%);
}
.theme-btn.gradient-btn:hover {
  background: #ff6600;
}
.theme-btn:hover {
  background: #2371ff;
  color: #ffffff;
}
.theme-btn:hover:before {
  left: 120%;
  transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.25;
}

.z-index {
  z-index: 2;
  position: relative;
}

.pb-310 {
  padding-bottom: 310px;
}

.pt-260 {
  padding-top: 260px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-235 {
  padding-top: 235px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-210 {
  padding-top: 210px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  width: inherit;
}

.swiper-container-horizontal.common-dots {
  position: relative;
}
.swiper-container-horizontal.common-dots .slide-dots {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  display: inline-block;
  margin: 0px 5px;
  padding: 0px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #c6c9cb;
  display: inline-block;
  outline: none;
  cursor: pointer;
  background: none;
  font-size: 0px;
  opacity: 1;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #2371ff;
}

/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

/*-- Overlay Color --*/
[data-overlay="light"]::before {
  background-color: #ffffff;
}

[data-overlay="dark"]::before {
  background-color: #222;
}

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1;
}

[data-opacity="2"]::before {
  opacity: 0.2;
}

[data-opacity="3"]::before {
  opacity: 0.3;
}

[data-opacity="4"]::before {
  opacity: 0.4;
}

[data-opacity="5"]::before {
  opacity: 0.5;
}

[data-opacity="6"]::before {
  opacity: 0.6;
}

[data-opacity="7"]::before {
  opacity: 0.7;
}

[data-opacity="8"]::before {
  opacity: 0.8;
}

[data-opacity="9"]::before {
  opacity: 0.9;
}

/* 02. Header CSS */
.header-menu.header-sticky.sticky-menu,
.mobile-header.header-sticky.sticky-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  background: #fff;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky-menu .header-menu-bg-3 {
  box-shadow: none;
  padding: 0px 0px;
}

.header-top {
  position: relative;
}
.header-top:before {
  position: absolute;
  content: "";
  height: 60px;
  width: 210px;
  top: 0px;
  left: 0px;
}

ul li {
  list-style: none;
}

.header-top-left {
  padding: 18px 0px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-left {
    text-align: center;
  }
}
.header-top-left:after {
  position: absolute;
  content: "";
  top: 0px;
  right: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 60px 22px;
  border-color: transparent transparent #f3f6f9 transparent;
}
.header-top-left span {
  color: #ffffff;
  display: block;
}
.header-top-left span i {
  font-size: 20px;
  vertical-align: bottom;
  color: #ffffff;
  margin-right: 5px;
  line-height: 1;
  animation: rotated 2s infinite linear;
  display: inline-block;
}

.header-top-right ul {
  text-align: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-right ul {
    text-align: center;
  }
}
.header-top-right ul li {
  color: #151735;
  display: inline-block;
  padding: 18px 39px;
  border-right: 1px solid #d6d8db;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-right ul li {
    padding: 18px 25px;
  }
}
.header-top-right ul li:last-child {
  padding-right: 0px;
  border-right: 0px;
}
.header-top-right ul li i {
  font-size: 20px;
  vertical-align: middle;
  color: #ff6600;
  margin-right: 5px;
}

li.header--social a i {
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
  display: inline-block;
  margin-right: 18px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
li.header--social a:last-child i {
  margin-right: 0px;
}
li.header--social a:hover i {
  color: white;
}

.header-menu {
  position: relative;
}
.header-menu:before {
  height: 100%;
  width: 210px;
  top: 0px;
  right: 0px;
  background: #2371ff;
  position: absolute;
  content: "";
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-menu:before {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-menu:before {
    display: none;
  }
}
.header-menu.header-menu-2:before {
  display: none;
}

.main-menu ul li {
  display: inline-block;
  margin: 0px 35px;
  list-style: none !important;
  position: relative;
  /*submenu start*/
  /*submenu end*/
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li {
    margin: 0px 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li {
    margin: 0px 10px;
  }
}
.main-menu ul li a {
  font-size: 16px;
  color: #212237;
  display: block;
  padding: 38px 0px;
  font-weight: 600;
  text-transform: uppercase;
}
.main-menu ul li .sub-menu {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 119%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 240px;
  z-index: 9;
  border-top: 4px solid #ff6600;
  text-align: left;
  padding: 15px 0;
}
.main-menu ul li .sub-menu li {
  display: block;
  margin: 0px;
  padding: 8px 25px 8px 25px;
}
.main-menu ul li .sub-menu li a {
  padding: 0px;
  display: inline-block;
  color: #212237;
  position: relative;
}
.main-menu ul li .sub-menu li a:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #ff6600;
}
.main-menu ul li .sub-menu li:hover a {
  color: #ff6600;
}
.main-menu ul li .sub-menu li:hover a:before {
  width: 100%;
  left: 0;
  right: auto;
}
.main-menu ul li:hover a {
  color: #ff6600;
}
.main-menu ul li:hover.menu-item-has-children::after {
  color: #ff6600;
}
.main-menu ul li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.main-menu ul li.menu-item-has-children {
  position: relative;
}
.main-menu ul li.menu-item-has-children:after {
  position: absolute;
  content: "";
  font-size: 12px;
  color: #212237;
  top: 50%;
  transform: translateY(-50%);
  right: -14px;
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li.menu-item-has-children:after {
    right: -10px;
  }
}

.header-right {
  background: #2371ff;
  padding: 10px 0px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right {
    background: none;
  }
}
.header-right:before {
  height: 100%;
  width: 41px;
  top: 0px;
  left: 0px;
  background: #ffffff;
  position: absolute;
  content: "";
  z-index: 1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header-right:before {
    width: 41px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-right:before {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right:before {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right:before {
    display: none;
  }
}
.header-right ul li {
  display: inline-block;
  margin-right: 35px;
  vertical-align: middle;
}
.header-right ul li:last-child {
  margin-right: 0px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header-right ul li {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-right ul li {
    margin-right: 10px;
  }
}
.header-right ul li span {
  font-size: 24px;
  color: #ffffff;
  display: inline-block;
  font-weight: 600;
}
.header-right ul li span i {
  font-size: 36px;
  color: #ffffff;
  display: inline-block;
  height: 80px;
  width: 80px;
  text-align: center;
  background: #ff6600;
  border: 5px solid #ffffff;
  border-radius: 50%;
  line-height: 60px;
  margin-right: 20px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header-right ul li span i {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-right ul li.user__text {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right ul li.user__text {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right ul li.user__text {
    display: none;
  }
}
.header-right ul li a {
  vertical-align: middle;
}
.header-right.header-right-2 {
  background: transparent;
}
.header-right.header-right-2 ul .language {
  margin-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right.header-right-2 ul .language {
    display: none;
  }
}
.header-right.header-right-2:before {
  display: none;
}

.side-menu-icon {
  position: relative;
}

.mobile-menu.mean-container {
  clear: both;
  overflow: hidden;
}

.contact-infos {
  margin-top: 30px;
}
.contact-infos h4 {
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 20px;
}
.contact-infos ul li {
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 15px;
  list-style: none;
}
.contact-infos ul li:last-child {
  margin-bottom: 0px;
}
.contact-infos ul li i {
  color: #ff6600;
  vertical-align: middle;
  width: 25px;
  display: inline-block;
}

.sidebar__menu--social {
  margin-top: 25px;
}
.sidebar__menu--social a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  background: #393f53;
  color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 5px;
}
.sidebar__menu--social a:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0%;
  background: #ff6600;
  z-index: -1;
  transform: rotate(90deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar__menu--social a:hover:before {
  width: 100%;
}
.sidebar__menu--social a:hover i {
  transform: rotate(360deg);
}
.sidebar__menu--social a:last-child {
  margin-right: 0px;
}

.header-top-left-2:after {
  border-color: transparent transparent #1d284b transparent;
}

.header-top-right-2 ul li {
  color: #ffffff;
  border-right: 1px solid #4a536f;
}

.language {
  position: relative;
  margin-right: 40px;
}
.language:after {
  position: absolute;
  content: "";
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.language:before {
  position: absolute;
  content: "";
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.language .language-dropdown {
  background: #1d284b;
  padding: 15px 25px;
  position: absolute;
  top: 140%;
  right: -20px;
  opacity: 0;
  visibility: hidden;
  width: 120px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-align: left;
  border-top: 4px solid #ff6600;
}
.language .language-dropdown a {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  display: block;
  padding: 5px 0px;
}
.language .language-dropdown a:hover {
  color: #ff6600;
}
.language:hover {
  color: #ff6600;
}
.language:hover .language-dropdown {
  opacity: 1;
  visibility: visible;
  top: 120%;
}

.header-top-3:before {
  display: none;
}

.header-top-left-3 {
  background: transparent;
  padding: 0px;
}
.header-top-left-3:after {
  display: none;
}
.header-top-left-3 span i {
  height: 48px;
  width: 48px;
  text-align: center;
  line-height: 54px;
  background: #ff6600;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  animation: none;
}

.header-top-right-3 ul li {
  border-color: rgba(255, 255, 255, 0.2);
  padding: 12px 39px;
}

.header--terms a {
  color: #ffffff;
  display: inline-block;
  position: relative;
  padding: 0px 10px;
  border-right: 2px solid #ffffff;
  line-height: 1;
}
.header--terms a:hover {
  color: #ff6600;
}
.header--terms a:last-child {
  padding-right: 0px;
  border-right: 0px;
}

.header__widget {
  display: inline-block;
  padding: 0px 32px;
  border-right: 1px solid #dddee0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__widget {
    padding: 0px 6px;
    border-right: 0px;
  }
}
.header__widget:last-child {
  padding-right: 0px;
  border-right: 0px;
}
.header__widget--icon {
  width: 60px;
  float: left;
}
.header__widget--icon i {
  font-size: 45px;
  color: #ff6600;
}
.header__widget--text {
  overflow: hidden;
}
.header__widget--text h4 {
  font-size: 20px;
  margin-bottom: 0px;
}
.header__widget .header__widget--icon__phone i {
  animation: phone-icon-animate 1s ease-in-out infinite;
  display: inline-block;
}

.header-menu-3 {
  margin-bottom: -45px;
}

.header-menu-bg-3 {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 6, 91, 0.06);
  padding: 0px 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .header-menu-bg-3 {
    padding: 0px;
    background: transparent;
  }
}


/* 03. Hero CSS */

/* 04. Cta CSS */
.cta-area {
  position: relative;
  z-index: 4;
}

.cta--wrapper {
  display: flex;
  flex-basis: 50%;
  border-radius: 10px;
  margin-top: -80px;
  position: relative;
  z-index: 4;
  overflow: hidden;
  box-shadow: 0px 15px 20px 0px rgba(0, 6, 91, 0.05);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta--wrapper {
    display: inherit;
  }
}
@media (max-width: 767px) {
  .cta--wrapper {
    display: inherit;
  }
}

.cta--single {
  display: inline-flex;
  align-items: center;
  padding: 45px 70px 40px 70px;
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta--single {
    padding: 45px;
  }
}
@media (max-width: 767px) {
  .cta--single {
    padding: 25px;
  }
}
.cta--single .cta--icon {
  font-size: 70px;
  color: #ffa366;
}
.cta--single .cta--link {
  display: inline-block;
  margin-left: auto;
  margin-right: 20px;
}
.cta--single .cta--link .cta--link__icon {
  height: 58px;
  width: 58px;
  line-height: 50px;
  border: 2px solid #ffa366;
  text-align: center;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}
.cta--single .cta--link .cta--link__icon i {
  margin-left: 28px;
  color: #ffffff;
}
.cta--single .cta--link .cta--link__icon i:hover {
  color: #ff6600;
}

.cta--single.theme-bg i:hover {
  color: #2371ff !important;
}

.cta--title {
  font-size: 36px;
  color: #ffffff;
  margin-left: -25px;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .cta--title {
    font-size: 22px;
  }
}

.cta--single.black-soft-bg .cta--icon {
  color: #777e93;
}
.cta--single.black-soft-bg .cta--link__icon {
  border: 2px solid #777e93;
}

.appoint-cta-area {
  margin-top: -90px;
}

.appoint-cta-space {
  padding: 90px 100px 75px 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .appoint-cta-space {
    padding: 40px 50px 25px 50px;
  }
}
@media (max-width: 767px) {
  .appoint-cta-space {
    padding: 30px;
  }
}

.aappoint {
  margin-bottom: 15px;
}
.aappoint input,
.aappoint select {
  color: #83868c;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 70px;
  background: #ffffff;
  font-weight: 500;
  border: 1px solid transparent;
  font-family: "Saira Semi Condensed", sans-serif;
  outline: none;
  padding: 0px 25px;
}
.aappoint button {
  border-radius: 0px;
  display: block;
  height: 70px;
  line-height: 70px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aappoint button {
    padding: 0px 30px;
  }
}

.aappoint input::-moz-placeholder {
  color: #83868c;
  font-size: 14px;
  font-weight: 500;
  font-family: "Saira Semi Condensed", sans-serif;
  opacity: 1;
}
.aappoint input::placeholder {
  color: #83868c;
  font-size: 14px;
  font-weight: 500;
  font-family: "Saira Semi Condensed", sans-serif;
  opacity: 1;
}

.aappoint__title {
  margin-bottom: 0px;
  font-size: 27px;
}

.newsletter-area {
  margin-bottom: -90px;
}

.anewsletter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .anewsletter {
    display: inherit;
  }
}
@media (max-width: 767px) {
  .anewsletter {
    display: inherit;
  }
}
.anewsletter h2 {
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .anewsletter h2 {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .anewsletter h2 {
    margin-bottom: 30px;
  }
}

.anewsletter--bg {
  padding: 80px 100px;
  background: #ff6600;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .anewsletter--bg {
    padding: 60px;
  }
}
@media (max-width: 767px) {
  .anewsletter--bg {
    padding: 40px;
    padding-bottom: 45px;
  }
}
.anewsletter--bg .news__shape {
  position: absolute;
  z-index: 1;
}
.anewsletter--bg .news__shape1 {
  left: 100px;
  top: -60px;
  animation: float-bob-y infinite 3s linear;
}
.anewsletter--bg .news__shape2 {
  right: 108px;
  bottom: -60px;
  animation: float-bob-y infinite 3s linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-mar-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (max-width: 767px) {
  .custom-mar-20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-pad-20 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .custom-pad-20 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.newsletter__wrapper--3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .newsletter__wrapper--3 {
    display: inherit;
  }
}

.news__subscribe {
  flex-basis: 50%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .news__subscribe {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.news__subscribe .field {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}
.news__subscribe .field:after {
  position: absolute;
  content: "";
  top: 30px;
  right: 30px;
  color: #83868c;
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
}
.news__subscribe .field input {
  height: 80px;
  width: 350px;
  background: #ffffff;
  color: #83868c;
  display: inline-block;
  border-radius: 50px;
  padding: 0px 30px;
  border: none;
  outline: none;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .news__subscribe .field input {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .news__subscribe .field input {
    width: 350px;
  }
}
@media (max-width: 767px) {
  .news__subscribe .field {
    margin-right: 0px;
  }
}
.news__subscribe button {
  height: 80px;
  line-height: 80px;
  padding: 0px 50px;
  margin-bottom: 20px;
}

.news__service {
  flex-basis: 50%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .news__service {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.news__service--number__icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  line-height: 65px;
  border: 5px solid #cbced8;
  display: inline-block;
  background: #ffffff;
  text-align: center;
  margin-right: 15px;
  vertical-align: middle;
  animation: pulse 8s infinite;
}
@media (max-width: 767px) {
  .news__service--number__icon {
    margin-bottom: 20px;
  }
}

.news__service--number__btn {
  display: inline-block;
  vertical-align: middle;
}
.news__service--number__btn a {
  height: 80px;
  line-height: 80px;
  padding: 0px 45px;
  font-size: 24px;
  font-weight: 600;
}

.news__service--number {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .news__service--number {
    display: inherit;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .news__service--number {
    display: flex;
  }
}

.anewsletter__btn a {
  vertical-align: middle;
}

/* 05. About CSS */
.aabout-img-space {
  margin-right: 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aabout-img-space {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aabout-img-space {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aabout-img-space {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .aabout-img-space {
    margin-right: 0px;
  }
}

.aabout-img {
  overflow: hidden;
}
.aabout-img img {
  width: 100%;
}

.aabout-img:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.img-lg img {
  min-height: 590px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .img-lg img {
    min-height: 560px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .img-lg img {
    min-height: 545px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .img-lg img {
    min-height: 490px;
  }
}
@media (max-width: 767px) {
  .img-lg img {
    min-height: inherit;
  }
}

.aabout-img_text {
  padding: 28px 70px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aabout-img_text {
    padding: 28px 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aabout-img_text {
    padding: 25px;
  }
}
@media (max-width: 767px) {
  .aabout-img_text {
    padding: 28px 55px;
  }
}

.aabout-img_text-title {
  font-size: 72px;
  line-height: 0.9;
  color: #ffffff;
  margin-bottom: 0px;
}
.aabout-img_text-title span {
  font-size: 60px;
  line-height: 1.09;
}

.aabout-qoute {
  padding: 40px 60px;
  background: #f3f5f9;
  position: relative;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .aabout-qoute {
    padding: 25px 30px;
  }
}
.aabout-qoute i {
  font-size: 80px;
  color: #ffffff;
  position: absolute;
  top: 35px;
  right: 60px;
  animation: float-bob-x infinite 8s linear;
}
@media (max-width: 767px) {
  .aabout-qoute i {
    right: 20px;
  }
}
.aabout-qoute h4 {
  font-size: 27px;
  line-height: 1.3;
  margin-bottom: 0px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .aabout-qoute h4 br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aabout-qoute h4 br {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .aabout-qoute h4 {
    padding-right: 120px;
  }
}

.aabout--profile {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .aabout--profile {
    margin-left: 0px;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .aabout--profile {
    margin-left: 20px;
    margin-top: 0px;
  }
}
.aabout--profile__img {
  height: 60px;
  width: 60px;
  float: left;
}
.aabout--profile__img img {
  border-radius: 50%;
}
.aabout--profile__text {
  margin-left: 80px;
}
.aabout--profile__text h4 {
  font-size: 27px;
  margin-bottom: 3px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aabout--profile__text h4 {
    font-size: 26px;
  }
}

.aabout--profile__wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .aabout--profile__wrapper {
    display: inherit;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .aabout--profile__wrapper {
    display: flex;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aabout-text {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aabout-text {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aabout-text {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .aabout-text {
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  br {
    display: none;
  }
}

.aabout--profile2 {
  margin-left: 0px;
}
@media (max-width: 767px) {
  .aabout--profile2 {
    overflow: hidden;
  }
}
.aabout--profile2 h4 {
  margin-bottom: 0px;
}
.aabout--profile2 h4 br {
  display: inline-block;
}
.aabout--profile2:hover img {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.aabout--profile__number {
  padding: 35px 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aabout--profile__number {
    padding: 35px;
  }
}
.aabout--profile__number h4 {
  margin-bottom: 0px;
}
.aabout--profile__number span {
  display: block;
  margin-bottom: 10px;
}

.aabout--profile__para p {
  margin-bottom: 0px;
}

.aabout__since {
  text-align: center;
  height: 200px;
  width: 200px;
  background: #ff6600;
  border: 9px solid #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
  z-index: 2;
  border-radius: 50%;
  animation: pulse-grey 8s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .aabout__since {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aabout__since {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aabout__since {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 767px) {
  .aabout__since {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.aabout__since span {
  font-size: 24px;
  display: block;
  margin-bottom: 0px;
  color: #ffffff;
}
.aabout__since h3 {
  font-size: 40px;
  margin-bottom: 0px;
  color: #ffffff;
}

.about-back-bg {
  position: relative;
}
.about-back-bg:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 30%;
  background: #f3f6f9;
  top: 0px;
  left: 0px;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-back-bg:before {
    display: none;
  }
}
@media (max-width: 767px) {
  .about-back-bg:before {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aabout-img-space2 {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aabout-img-space2 {
    margin-right: 0px;
  }
  .aabout-img-space2 img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .aabout-img-space2 {
    margin-right: 0px;
  }
  .aabout-img-space2 img {
    width: 100%;
  }
}

.aabout__fact--single__title {
  font-size: 50px;
  line-height: 0.6;
  margin-bottom: 25px;
}

.aabout__fact--single__subtitle {
  font-size: 27px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .aabout-text-3 {
    padding-bottom: 85px;
  }
}

/* 06. Fact CSS */
.afact:hover img {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.afact--img {
  padding-left: 30px;
  position: relative;
}
.afact--img:before {
  position: absolute;
  content: "";
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #ffb482;
  background: transparent;
  display: inline-block;
  top: 0px;
  left: 0px;
}

.afact--text {
  display: flex;
  align-items: center;
}
.afact--text__content h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .afact--text__content h4 {
    font-size: 18px;
  }
}
.afact--text__content h3 {
  font-size: 27px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .afact--text__content h3 {
    font-size: 24px;
  }
}

.afact--text__title h2 {
  font-size: 65px;
  margin-right: 15px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .afact--text__title h2 {
    margin-right: 10px;
    font-size: 54px;
  }
}

.afact--text__title2 h2 {
  color: #ff6600;
}

@media (max-width: 767px) {
  .fact-area-2.pt-130 {
    padding-top: 40px;
    padding-bottom: 35px;
  }
}

.fact-area-3 {
  position: relative;
  margin-bottom: -140px;
}
.fact-area-3.about-fact-area-3:before {
  left: auto;
  right: 0px;
}

.afact__3:hover i {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.afact__3--icon {
  width: 100px;
  float: left;
}
.afact__3--icon i {
  font-size: 80px;
  color: #ffffff;
  margin-top: 10px;
  display: inline-block;
}

.afact__3--text {
  overflow: hidden;
}
.afact__3--text h2 {
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 5px;
}
.afact__3--text span {
  font-size: 24px;
  color: #ffffff;
  display: block;
  font-weight: 600;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .afact__3--text span {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .afact__3--text span {
    font-size: 19px;
  }
}

.about-theme-bg-blue {
  padding-left: 165px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  (max-width: 767px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .about-theme-bg-blue {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.team-fact-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* 07. Service CSS */
.service-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aservice {
  padding: 45px 45px 42px 45px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #dfe4ea;
  border-radius: 5px;
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .aservice {
    padding: 36px 25px 31px 25px;
  }
}
.aservice__icon {
  height: 80px;
  width: 80px;
  line-height: 90px;
  text-align: center;
  display: inline-block;
  background: #f1f4f7;
  margin-bottom: 25px;
  border-radius: 5px;
}
.aservice__icon i {
  font-size: 45px;
  color: #2371ff;
  vertical-align: middle;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.aservice__text span {
  color: #151735;
  margin-bottom: 10px;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.aservice-box {
  position: relative;
}
.aservice-box:after {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 17.5px 0 17.5px;
  border-color: #ff6600 transparent transparent transparent;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.aservice__text--title {
  font-size: 24px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 0px;
}

.custom-service-tab .nav-link.active .aservice {
  border-color: #ff6600;
  background: #ff6600;
}
.custom-service-tab .nav-link.active .aservice:before {
  height: 100%;
}
.custom-service-tab .nav-link.active .aservice .aservice__text--title,
.custom-service-tab .nav-link.active .aservice span {
  color: #ffffff;
}
.custom-service-tab .nav-link.active .aservice i {
  color: #32344f;
  transform: rotateY(360deg);
  display: inline-block;
}
.custom-service-tab .nav-link.active .aservice-box:after {
  opacity: 1;
  visibility: visible;
}

.aservice__img {
  position: relative;
  overflow: hidden;
}
.aservice__img img {
  width: 100%;
}
.aservice__img--text {
  padding: 35px 25px;
  background: #ffffff;
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 360px;
}
@media (max-width: 767px) {
  .aservice__img--text {
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 20px;
  }
}
.aservice__img--text:before {
  height: 0%;
  width: 4px;
  top: 0px;
  left: 0px;
  position: absolute;
  content: "";
  background: #ff6600;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}
.aservice__img--text p {
  margin-bottom: 10px;
}
.aservice__img--text__link {
  color: #ff6600;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.aservice__img--text__link i {
  margin-left: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.aservice__img--text__link:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid #ff6600;
  top: 53%;
  transform: translateY(-50%);
  right: 14px;
  border-radius: 50%;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.aservice__img--text__link:hover i {
  margin-left: 15px;
}
.aservice__img--text__link:hover:before {
  right: 14px;
  border-color: #4f5168;
}
.aservice__img--text:hover:before {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.aservice__img--text__link img {
  width: inherit;
}

.aservice__img:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

@media (max-width: 767px) {
  .service__header__btn {
    text-align: left !important;
    margin-top: 20px;
  }
}

.aservice__list a {
  text-decoration: none !important;
}
.aservice__list:hover i {
  animation-name: animation-pulse-shrink;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: inline-block;
}

.custom-service-tab {
  margin-left: -15px;
  margin-right: -15px;
}

.nav-item {
  flex: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.nav-link {
  border: none;
  padding: 0px;
  width: 100%;
  background: none;
  cursor: pointer;
}

.service__header__btn a {
  vertical-align: bottom;
}

.ablog__text--service .ablog__text--title {
  margin-bottom: 10px;
  padding-top: 5px;
}
.ablog__text h4 a {
  text-decoration: none !important;
}
.ablog__text--service .ablog__btn a {
  border-radius: 50px;
  text-decoration: none;
}
.ablog__text--service p {
  margin-bottom: 5px;
}

.aservice__list--icon {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  float: left;
  margin-right: 30px;
  line-height: 80px;
  background: #fae5d8;
  text-align: center;
  animation: pulse-orange 8s infinite;
}
.aservice__list--icon i {
  color: #ff6600;
  font-size: 34px;
  vertical-align: middle;
}

.aservice__list--text {
  overflow: hidden;
}
.aservice__list--text p {
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aservice__list--text p {
    padding-right: 275px;
  }
}
.aservice__list--text.aservice__list--text2 p {
  padding-right: 0px;
}

.aservice__list--text__title {
  font-size: 27px;
}
.aservice__list--text__title:hover {
  color: #ff6600;
}

.service-area-2 {
  margin-top: -220px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service-area-2 br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-area-2 {
    padding-top: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area-2 {
    margin-top: -170px;
    padding-top: 110px;
  }
}
@media (max-width: 767px) {
  .service-area-2 {
    padding-top: 110px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aservice--img__2 {
    margin-top: 30px;
  }
}
.aservice--img__2 img {
  max-width: inherit;
  margin-left: 100px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .aservice--img__2 img {
    max-width: 100%;
    margin-left: 0px;
  }
}

@media (max-width: 767px) {
  .aservice__list.mb-55 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .aservice--img__2.mb-30 {
    margin-top: 10px;
  }
}

div.service--afeature__icon {
  padding: 0px;
  border: 10px solid rgba(34, 112, 255, 0.25);
  animation: pulse 8s infinite;
}
div.service--afeature__icon i {
  height: 160px;
  width: 160px;
  line-height: 160px;
  vertical-align: middle;
  font-size: 70px;
  line-height: 175px;
  animation: pulse-blue 8s infinite;
}
@media (max-width: 767px) {
  div.service--afeature__icon i {
    height: 120px;
    width: 120px;
    line-height: 130px;
    font-size: 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .service__contact--img {
    margin-right: 0px;
  }
}
.service__contact--img img {
  max-width: inherit;
  margin-left: -165px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .service__contact--img img {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.service__contact--form input,
.service__contact--form textarea {
  height: 70px;
  width: 100%;
  display: block;
  border: 1px solid #dce1e4;
  border-radius: 35px;
  background: rgba(247, 247, 247, 0);
  padding: 0px 30px;
  outline: none;
  font-size: 16px;
  color: #83868c;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.service__contact--form input::-moz-placeholder,
.service__contact--form textarea::-moz-placeholder {
  color: #83868c;
  opacity: 1;
}
.service__contact--form input::placeholder,
.service__contact--form textarea::placeholder {
  color: #83868c;
  opacity: 1;
}
.service__contact--form input:focus,
.service__contact--form textarea:focus {
  border-color: rgba(34, 112, 255, 0.35);
}
.service__contact--form textarea {
  height: 170px;
  padding: 20px 30px;
  border-radius: 30px;
}

.service__sidebar--widget {
  padding: 35px 30px 40px 30px;
}

.service__sidebar--widget__title {
  font-size: 27px;
  margin-bottom: 25px;
}
.service__sidebar--widget__title span {
  color: #ff6600;
}
.widget__links ul li{
  border: 2px solid red !important;
  margin-left: 0px !important;
  list-style: none !important;
  text-decoration: none !important;
}

.service__sidebar--widget__links li {
  list-style: none;
  padding: 21px 25px 19px 25px;
  background: #ffffff;
  margin-bottom: 12px;
  line-height: 1;
  list-style: none;
}
.service__sidebar--widget__links li:last-child {
  margin-bottom: 0px;
}
.service__sidebar--widget__links li a {
  list-style: none;

  font-size: 16px;
  color: #4f5168;
  line-height: 1;
}
.service__sidebar--widget__links li a i {
  font-size: 14px;
  color: #ff6600;
  float: right;
  margin-top: 3px;
}
.service__sidebar--widget__links li:hover a {
  color: #ff6600;
}

.service__sidebar--widget__info {
  padding: 10px 25px 10px 25px;
  background: #ffffff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .service__sidebar--widget__info {
    padding: 0px 15px 0px 15px;
  }
}
.service__sidebar--widget__info li {
  list-style: none;
  padding: 25px 0px;
  border-bottom: 1px solid #dce1e4;
}
.service__sidebar--widget__info li:last-child {
  border-bottom: 0px;
}
.service__sidebar--widget__info li:hover i {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.service__sidebar--widget__info .service__sidebar--widget__info--icon {
  float: left;
  margin-right: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .service__sidebar--widget__info .service__sidebar--widget__info--icon {
    margin-right: 10px;
  }
}
.service__sidebar--widget__info .service__sidebar--widget__info--icon i {
  font-size: 26px;
  color: #ff6600;
  margin-top: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .service__sidebar--widget__info .service__sidebar--widget__info--icon i {
    font-size: 20px;
  }
}
.service__sidebar--widget__info .service__sidebar--widget__info--text {
  overflow: hidden;
}
.service__sidebar--widget__info .service__sidebar--widget__info--text span {
  display: block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .service__sidebar--widget__info .service__sidebar--widget__info--text span {
    font-size: 15px;
  }
}
.service__sidebar--widget__info
  .service__sidebar--widget__info--text
  span:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .service__sidebar--widget__btn {
    display: flex;
    justify-content: space-between;
  }
}
.service__sidebar--widget__btn a {
  font-size: 20px;
  color: #ffffff;
  height: 60px;
  line-height: 60px;
  background: #ff6600;
  display: block;
  padding: 0px 17px;
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .service__sidebar--widget__btn a {
    margin-bottom: 0px;
    flex-basis: 48%;
  }
}
.service__sidebar--widget__btn a:last-child {
  margin-bottom: 0px;
}
.service__sidebar--widget__btn a i {
  font-size: 30px;
  padding-right: 17px;
  border-right: 1px solid rgba(255, 255, 255, 0.302);
  vertical-align: middle;
  height: 60px;
  line-height: 60px;
  margin-top: -4px;
  margin-right: 15px;
}
.service__sidebar--widget__btn a:hover {
  background: #2371ff;
}

.service-details-subtitle {
  font-size: 34px;
}

.service-details-list li {
  list-style: none;
  padding-left: 28px;
  position: relative;
  margin-bottom: 25px;
}
.service-details-list li:before {
  content: "";
  font-size: 16px;
  color: #ff6600;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  top: 2px;
  left: 0px;
}

.service__details--contact__form .field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .service__details--contact__form .field-wrapper {
    display: inherit;
  }
}
.service__details--contact__form .field-wrapper .field {
  width: 48.5%;
}
@media (max-width: 767px) {
  .service__details--contact__form .field-wrapper .field {
    width: 100%;
  }
}

.service-details img,
.service-details-inner-img img {
  width: 100%;
}
.service-details img:hover,
.service-details-inner-img img:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.aservice--2 {
  margin-top: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .aservice--2 {
    margin-top: 0px;
  }
}

/* 08. Contact CSS */


/* 09. Team CSS */
.ateam {
  position: relative;
  z-index: 4;
  background: transparent;
}

.ateam__img {
  position: relative;
  overflow: hidden;
}
.ateam__img img {
  width: 100%;
}
.ateam__img--social {
  position: absolute;
  height: 100%;
  background: #1d284b;
  top: 0px;
  right: -55px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ateam__img--social ul {
  padding: 22px 18px;
  background: #ff6600;
}
.ateam__img--social ul li {
  margin: 10px 0px;
  list-style: none;
}
.ateam__img--social ul li a {
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
}
.ateam__img--social ul li a:hover {
  color: #151735;
  transform: rotateY(360deg);
}

.ateam__text {
  background: #222e54;
  padding: 20px 25px 17px 25px;
}
.ateam__text span {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}

.ateam__text--title {
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 0px;
}
.ateam__text--title:hover {
  color: #ff6600;
}

.ateam:hover img {
  opacity: 0.7;
  transform: scale3d(1.1, 1.1, 1.1);
}
.ateam:hover .ateam__img--social {
  right: 0px;
}

.team-shape {
  position: absolute;
  font-size: 72px;
  color: #616981;
}
@media (max-width: 767px) {
  .team-shape {
    display: none;
  }
}

.team-shape-1 {
  top: 180px;
  left: 190px;
  animation: team-shape-1 20s infinite linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .team-shape-1 {
    top: 80px;
    left: 120px;
    animation: scale-up-one infinite 3s linear;
  }
}

.team-shape-2 {
  bottom: 200px;
  left: 200px;
  animation: scale-up-one infinite 3s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .team-shape-2 {
    bottom: 10px;
    left: 130px;
  }
}

.team-shape-3 {
  top: 140px;
  right: 205px;
  animation: team-shape-3 20s infinite linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .team-shape-3 {
    top: 80px;
    right: 120px;
    animation: scale-up-one infinite 3s linear;
  }
}

.team-shape-4 {
  bottom: 190px;
  right: 135px;
  animation: rotated 5s infinite linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .team-shape-4 {
    bottom: 10px;
    right: 120px;
  }
}

.ateam__3 {
  position: relative;
  overflow: hidden;
}
.ateam__3 img {
  width: 100%;
}
.ateam__3 .ateam__3--overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(4, 14, 46, 0.3);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.ateam__3 .ateam__3--overlay__social {
  position: absolute;
  top: 30%;
  left: 0px;
  right: 0px;
  text-align: center;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.ateam__3 .ateam__3--overlay__social a {
  height: 45px;
  line-height: 45px;
  width: 45px;
  text-align: center;
  font-size: 15px;
  color: #151735;
  display: inline-block;
  margin: 0px 4px;
  background: #ffffff;
}
.ateam__3 .ateam__3--overlay__social a:hover {
  background: #ff6600;
  color: #ffffff;
}
.ateam__3 .ateam__3--overlay__social a:hover i {
  transform: rotate(360deg);
}
.ateam__3 .ateam__3--overlay__text {
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
  bottom: -100px;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.ateam__3:hover .ateam__3--overlay {
  opacity: 1;
  visibility: visible;
}
.ateam__3:hover .ateam__3--overlay .ateam__3--overlay__social {
  top: 50%;
}
.ateam__3:hover .ateam__3--overlay .ateam__3--overlay__text {
  bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ateam__3:hover .ateam__3--overlay .ateam__3--overlay__text {
    bottom: 30px;
  }
}

.ateam__3--title {
  font-size: 27px;
  margin-bottom: 0px;
}
.ateam__3--title:hover {
  color: #ff6600 !important;
}

.ateam__3--left {
  padding-left: 185px;
  padding-right: 120px;
}
@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .ateam__3--left {
    padding-right: 40px;
  }
  .ateam__3--left br {
    display: none;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ateam__3--left {
    padding-right: 40px;
    padding-left: 100px;
  }
  .ateam__3--left br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ateam__3--left {
    padding: 0px;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ateam__3--left p {
    padding-right: 250px;
  }
}

.about-team-area {
  background-position: center;
  background-repeat: repeat;
}

.about--ateam__text {
  background: #ffffff;
}
.about--ateam__text span,
.about--ateam__text h4 {
  color: #151735;
}

.team__sidebar-wrapper {
  padding: 40px 30px;
}

.team__sidebar--widget img {
  width: 100%;
}

.team--sidebar__info {
  padding: 30px 25px 0px 25px;
}
.team--sidebar__info .service__sidebar--widget__title {
  margin-bottom: 0px;
}

div.team__sidebar--social {
  padding: 10px 25px 30px 25px;
}
div.team__sidebar--social a {
  color: #bcbfc9;
}

.team--details__feature--icon {
  height: 70px;
  width: 70px;
  line-height: 80px;
  background: #ff6600;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
}
.team--details__feature--icon i {
  font-size: 45px;
  color: #ffffff;
  vertical-align: middle;
}

.team--details__feature--text__title {
  font-size: 19px;
  margin-bottom: 15px;
}
.team--details__feature--text__title:hover {
  color: #ff6600;
}

.team--skill__title {
  font-size: 27px;
  margin-bottom: 15px;
}

div.team__sidebar--social {
  text-align: inherit !important;
}

.team-details-wrapper .service-details-subtitle {
  font-size: 34px;
}

/* 10. Portfolio CSS */
.aportfolio__text {
  box-shadow: 0px 0px 30px 0px rgba(0, 5, 63, 0.1);
  background: #ffffff;
  padding: 25px 35px 30px 35px;
  margin: 0px 20px;
  margin-top: -45px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aportfolio__text {
    padding: 25px 25px 30px 25px;
  }
}
.aportfolio__text span {
  display: block;
  margin-bottom: 5px;
}

.aportfolio__text--title {
  font-size: 27px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aportfolio__text--title {
    font-size: 24px;
  }
}
.aportfolio__text--title:hover {
  color: #ff6600;
}

.aportfolio:hover img {
  opacity: 0.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aportfolio-menu {
    text-align: left !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .aportfolio-menu {
    text-align: left !important;
    margin-bottom: 40px;
  }
}
.aportfolio-menu button {
  border: 1px solid #dce1e4;
  height: 40px;
  line-height: 38px;
  padding: 0px 30px;
  border-radius: 50px;
  background: none;
  color: #151735;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  display: inline-block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aportfolio-menu button {
    padding: 0px 25px;
  }
}
@media (max-width: 767px) {
  .aportfolio-menu button {
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 10px;
  }
}
.aportfolio-menu button.active {
  background: #ff6600;
  color: #ffffff;
  border-color: #ff6600;
}
.aportfolio-menu button:first-child {
  margin-left: 0px;
}

.aportfolio__img {
  overflow: hidden;
  position: relative;
}
.aportfolio__img img {
  width: 100%;
}

.aportfolio:hover img {
  opacity: 0.7;
  transform: scale3d(1.1, 1.1, 1.1);
}
.aportfolio:hover .aportfolio__img--icon {
  height: 100%;
}
.aportfolio:hover .aportfolio__inner--text {
  background: #ff6600;
}
.aportfolio:hover .aportfolio__inner--text h4,
.aportfolio:hover .aportfolio__inner--text span {
  color: #ffffff;
}

.aportfolio__img--icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 0%;
  width: 100%;
  background: rgba(29, 40, 75, 0.4);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.aportfolio__img--icon a {
  height: 70px;
  width: 70px;
  line-height: 70px;
  vertical-align: middle;
  font-size: 18px;
  color: #ff6600;
  display: inline-block;
  background: #ffffff;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.aportfolio__img--icon a:hover {
  background: #ff6600;
  color: #ffffff;
}

@media (max-width: 767px) {
  .aportfolio-menu-inner {
    text-align: center !important;
  }
}
.aportfolio-menu-inner button {
  margin: 0px 5px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aportfolio-menu-inner button {
    padding: 0px 25px;
  }
}

.project--details__info {
  padding: 6px 30px;
}
.project--details__info ul li {
  padding: 24px 0px;
  border-bottom: 1px solid #ff944d;
  list-style: none;
}
.project--details__info ul li:last-child {
  border-bottom: 0px;
}
.project--details__info ul li span {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}
.project--details__info ul li h4 {
  margin-bottom: 0px;
  font-size: 27px;
  color: #ffffff;
}

.project-description-list ul {
  padding-top: 8px;
}
.project-description-list ul li {
  margin-bottom: 20px;
}

div.project-description-video {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  div.project-description-video {
    min-height: 450px;
  }
}
div.project-description-video:before {
  background: rgba(21, 32, 66, 0.85);
}

.project-description-img img {
  width: 100%;
}

/* 11. Choose CSS */
.choose-area {
  background-repeat: no-repeat;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-area {
    background-size: cover;
  }
}

.choose-bg-right {
  background-repeat: no-repeat;
  width: 54%;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  background-size: cover;
  margin-top: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose-bg-right {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-bg-right {
    width: 100%;
    margin: 0px;
  }
}
@media (max-width: 767px) {
  .choose-bg-right {
    width: 100%;
    margin: 0px;
  }
}

.choose--content {
  padding: 180px 0px 10px 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .choose--content br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose--content {
    padding: 180px 0px 10px 20px;
  }
  .choose--content br {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .choose--content {
    padding: 120px 0px 70px 0px;
  }
}

.achoose__icon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  line-height: 116px;
  border: 7px solid #c1d7ff;
  display: inline-block;
  text-align: center;
  float: left;
  margin-right: 30px;
  animation: pulse-blue 6s infinite;
}
@media (max-width: 767px) {
  .achoose__icon {
    margin-right: 0px;
    margin-bottom: 15px;
    float: inherit;
  }
}
.achoose__icon i {
  font-size: 48px;
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.achoose__icon.theme-bg {
  border-color: #ffd5b9;
  animation: pulse-orange 6s infinite;
}
.achoose__text p {
  margin-bottom: 0px;
}
.achoose:hover i {
  transform: rotateY(360deg);
  display: inline-block;
}

.achoose__text--title {
  font-size: 27px;
}

.choose-area-3 {
  background-position: center;
  background-repeat: repeat;
  overflow-x: hidden;
}

.choose--content__3 {
  padding: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .choose--content__3 {
    padding-top: 70px;
  }
}

.achoose__img--3 img {
  max-width: inherit;
}
@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .achoose__img--3 img {
    max-width: 725px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .achoose__img--3 img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .choose--feature__left {
    margin-right: 65px;
  }
}
@media (max-width: 767px) {
  .choose--feature__left {
    margin-right: 0px;
  }
}

.choose--feature__icon i {
  font-size: 60px;
  color: #ff6600;
}

.choose--feature__text p {
  margin-bottom: 0px;
}

.choose--feature__text--title {
  font-size: 27px;
  margin-bottom: 15px;
}
.choose--feature__text--title:hover {
  color: #ff6600;
}

.choose-fact-area {
  position: relative;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose-fact-area {
    padding-bottom: 255px;
  }
}
@media (max-width: 767px) {
  .choose-fact-area {
    padding-bottom: 85px;
  }
}
.choose-fact-area:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(21, 32, 66, 0.9);
  z-index: -1;
}

.choose--fact__icon {
  height: 100px;
  width: 100px;
  line-height: 100px;
  border: 2px dashed #ffffff;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.choose--fact__icon i {
  font-size: 48px;
  color: #ffffff;
  vertical-align: middle;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.choose--fact__text h2 {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 5px;
}
.choose--fact__text span {
  font-size: 20px;
  color: #ffffff;
  display: block;
}

.choose--fact {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.choose--fact:hover {
  transform: translateY(-5px);
}
.choose--fact:hover .choose--fact__icon {
  border-color: #ff6600;
  background: #ff6600;
}
.choose--fact:hover .choose--fact__icon i {
  transform: rotate(360deg);
  display: inline-block;
}

div.choose--afeature__icon {
  border-radius: 5px;
  padding: 6px;
}
div.choose--afeature__icon i {
  border-radius: 5px;
  height: 70px;
  width: 70px;
  line-height: 77px;
}

.choose-work-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* 12. Price CSS */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-area {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .pricing-area {
    padding-top: 120px;
  }
}

.aprice-bg {
  background: #f3f6f9;
  padding: 0px 15px 0px 15px;
}

.aprice {
  padding: 40px 35px;
  border: 1px solid #e8e7e7;
  background: #ffffff;
  transform: translateY(15px);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.aprice:hover {
  box-shadow: 0px 10px 20px 0px rgba(1, 23, 88, 0.05);
  border-color: #ffffff;
}
@media (max-width: 767px) {
  .aprice {
    padding: 25px 20px;
  }
}

.aprice__icon {
  width: 85px;
  float: left;
}
.aprice__icon i {
  font-size: 60px;
  color: #2371ff;
}

.aprice__icon--text span {
  margin-bottom: 5px;
  display: block;
}
.aprice__icon--text h4 {
  font-size: 27px;
  margin-bottom: 0px;
}

.aprice__price--wrapper {
  background: #f7f7f7;
  padding: 10px 10px 15px 10px;
  text-align: center;
}
.aprice__price--wrapper h2 {
  font-size: 45px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  margin-bottom: 0px;
  font-weight: 700;
}
.aprice__price--wrapper h2 span {
  font-weight: 500;
  font-size: 16px;
  color: #4f5168;
}

.aprice__list ul {
  margin: 35px 0px 40px 0px;
}
.aprice__list ul li {
  margin-bottom: 25px;
  list-style: none;
}
.aprice__list ul li:last-child {
  margin-bottom: 0px;
}
.aprice__list ul li i {
  height: 30px;
  width: 30px;
  border: 1px solid #ccc;
  color: #4f5168;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.aprice__list ul li:hover i {
  background: #ff6600;
  border-color: #ff6600;
  color: #ffffff;
}

.aprice-bg:hover .aprice__price--wrapper h2,
.aprice-bg:hover .aprice__price--wrapper span {
  color: #ff6600;
}
.aprice-bg:hover img {
  animation-name: animation-pulse-shrink;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* 13. Testimonial CSS */
.testimonial-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.atestimonial__img img {
  max-width: inherit;
  margin-left: -250px;
  margin-top: -60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .atestimonial__img img {
    margin-left: 0px;
    margin-top: -60px;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .atestimonial__img img {
    margin-left: 0px;
    margin-top: 0px;
    max-width: 100%;
  }
}

.atestimonial__client--img {
  width: 100px;
  margin-right: 20px;
}
.atestimonial__client--img img {
  border-radius: 50%;
  border: 4px solid #49526f;
}

.atestimonial__client--text__title {
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 5px;
}

.atestimonial-active .slide-prev,
.atestimonial-active .slide-next {
  position: absolute;
  color: #ffffff;
  font-size: 24px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  z-index: 3;
}
.atestimonial-active .slide-prev:hover,
.atestimonial-active .slide-next:hover {
  color: #ff6600;
}
.atestimonial-active .slide-prev {
  bottom: 28px;
  right: 100px;
}
@media (max-width: 767px) {
  .atestimonial-active .slide-prev {
    right: 49px;
    bottom: 6px;
  }
}
.atestimonial-active .slide-prev:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  vertical-align: middle;
  background: #8e94a5;
  top: 50%;
  transform: translateY(-50%);
  right: -16px;
}
.atestimonial-active .slide-next {
  bottom: 28px;
  right: 50px;
}
@media (max-width: 767px) {
  .atestimonial-active .slide-next {
    right: 0px;
    bottom: 6px;
  }
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: inherit;
}

.swiper-button-prev,
.swiper-button-next {
  top: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .atestimonial__text {
    padding: 80px 0px;
  }
}
@media (max-width: 767px) {
  .atestimonial__text {
    padding: 80px 0px;
  }
}

.testimonial-area-2 {
  background-position: center top;
  background-repeat: repeat;
  border-bottom: 1px solid #dce1e4;
}

.atestimonial__client--text__title2 {
  color: #151735;
}

.atestimonial-2 p {
  font-size: 20px;
  line-height: 1.7;
}
.atestimonial-2 img {
  border: 5px solid #ffffff;
  border-radius: 50%;
}

.swiper-container-horizontal.common-dots.testimonial-active-2 .slide-dots {
  left: 60%;
  bottom: 60px;
}
@media (max-width: 767px) {
  .swiper-container-horizontal.common-dots.testimonial-active-2 .slide-dots {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
  }
}

.atestimonial__img--2 img {
  max-width: inherit;
  margin-left: 65px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .atestimonial__img--2 img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .atestimonial__img--2 img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .atestimonial__img--2 img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .atestimonial__img--2 img {
    width: 100%;
    margin-left: 0px;
    padding-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .atestimonial__img--2 img {
    width: 100%;
    margin-left: 0px;
    padding-bottom: 45px;
  }
}

.testimonial-section-title {
  line-height: 1;
}
.testimonial-section-title span {
  line-height: 1;
}

.testimonial-area-3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.atestimonial__3--text {
  background: #ffffff;
  padding: 45px 40px;
  box-shadow: 0px 5px 20px 0px rgba(0, 6, 91, 0.05);
}
.atestimonial__3--text.about__test--text {
  box-shadow: none;
  border: 1px solid #dce1e4;
}
@media (max-width: 767px) {
  .atestimonial__3--text {
    padding: 35px 30px;
  }
}
.atestimonial__3--text__rating i {
  font-size: 16px;
  color: #ff6600;
  margin-right: 6px;
}
.atestimonial__3--text__rating i.far {
  color: #797979;
}
.atestimonial__3--text p {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0px;
}

.atestimonial__3--client {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 6, 91, 0.05);
  position: relative;
  z-index: 1;
}
.atestimonial__3--client.about__test--client {
  background: #f3f5f9;
  box-shadow: none;
}
@media (max-width: 767px) {
  .atestimonial__3--client {
    padding: 20px 30px;
  }
}
.atestimonial__3--client:before {
  height: 0%;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: auto;
  top: 0px;
  background: #ff6600;
  position: absolute;
  content: "";
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.atestimonial__3--client__img {
  width: 80px;
  margin-right: 20px;
  border-radius: 50%;
}
.atestimonial__3--client__img img {
  border: 5px solid #fff;
  border-radius: 50%;
}
.atestimonial__3--client__text span {
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 5px;
}

.atestimonial__3--client__text--title {
  font-size: 27px;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .atestimonial__3--client__text--title {
    font-size: 24px;
  }
}

.atestimonial__3 {
  position: relative;
}
.atestimonial__3:after {
  height: 0px;
  width: 0px;
  background: #ff6600;
  position: absolute;
  content: "";
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 100px;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.atestimonial__3:hover:after {
  width: 100px;
  height: 100px;
  visibility: visible;
}
@media (max-width: 767px) {
  .atestimonial__3:hover:after {
    height: 80px;
    width: 80px;
  }
}
.atestimonial__3:hover .atestimonial__3--client:before {
  height: 100%;
  visibility: visible;
  bottom: 0px;
  top: auto;
}
.atestimonial__3:hover .atestimonial__3--client span,
.atestimonial__3:hover .atestimonial__3--client h4 {
  color: #ffffff;
}
.atestimonial__3:hover .atestimonial__3--client img {
  border-color: #f8dac9;
}

.swiper-pagination2.swiper-pagination-clickable.swiper-pagination-bullets {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
}

/* 14. Blog CSS */
.blog__date {
  background: #ffffff;
  padding: 5px 20px;
  border-bottom: 4px solid #ff6600;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: -60px;
  z-index: 2;
  margin-bottom: 25px;
  left: 29px;
}
.blog__date h3 {
  font-size: 34px;
  margin-bottom: 0px;
}
.blog__date i {
  font-size: 48px;
}
.blog__date.blog__date--service__icon {
  padding: 12px 15px 7px 15px;
}

.ablog__text {
  padding: 42px 30px 0px 30px;
  border: 1px solid #dce1e4;
  position: relative;
}

.ablog__meta {
  margin-bottom: 15px;
}
.ablog__meta ul li {
  display: inline-block;
  list-style: none;
  margin-right: 15px;
}
.ablog__meta ul li:last-child {
  margin-right: 0px;
}
.ablog__meta ul li a:hover {
  color: #ff6600;
}
.ablog__meta ul li a i {
  font-size: 13px;
  margin-right: 2px;
}

.ablog__text--title {
  font-size: 27px;
  margin-bottom: 5px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ablog__text--title {
    font-size: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ablog__text--title {
    font-size: 24px;
  }
}
.ablog__text--title:hover {
  color: #ff6600;
}

.ablog__btn a {
  border-radius: 0px;
  height: 50px;
  line-height: 50px;
  margin: 10px 0px;
  text-decoration: none !important;
}

.ablog__img {
  overflow: hidden;
}
.ablog__img img {
  width: 100%;
}

.ablog:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}
.ablog:hover .ablog__user img {
  transform: none;
}
.ablog:hover .blog__date i {
  animation-name: animation-pulse-shrink;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: inline-block;
}

.ablog__user {
  display: flex;
  align-items: center;
}
.ablog__user--img {
  margin-right: 15px;
}
.ablog__user--title h5 {
  font-size: 20px;
  margin-bottom: 0px;
}

.ablog-2 p {
  margin-bottom: 25px;
}

.ablog__text--title2 {
  margin-bottom: 10px;
}

.ablog__text2 {
  padding: 42px 30px 30px 30px;
}

.ablog__user--title h5:hover {
  color: #ff6600;
}

.blog__date2 {
  background: #ff6600;
}

.ablog__img3 {
  position: relative;
}

.ablog__text3 {
  position: inherit;
  padding: 0px 20px 30px 20px;
  border: none;
}

.blog__date3 {
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0px;
}

.ablog__meta3 {
  box-shadow: 0px 5px 20px 0px rgba(0, 6, 91, 0.05);
  background: #ffffff;
  border-radius: 3px;
  padding: 24px 43px;
  margin-top: -36px;
  position: relative;
  margin-bottom: 35px;
}

.ablog__text3--btn a.grey-btn {
  background: none;
  height: 52px;
  line-height: 50px;
  padding: 0px 35px;
}
.ablog__text3--btn a.grey-btn:hover {
  background: #ff6600;
}

.ablog__text--title3 {
  margin-bottom: 15px;
}

.about--ablog__text3--btn a.theme-btn:hover {
  background: #2371ff;
  border-color: #2371ff;
}

.basic-pagination ul li {
  display: inline-block;
  margin-right: 10px;
}
.basic-pagination ul li a,
.basic-pagination ul li span {
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
  color: #151735;
  font-size: 20px;
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.basic-pagination ul li a:hover,
.basic-pagination ul li span.current {
  background: #ff6600;
  color: #ffffff;
}

.ablog-4 {
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  -moz-box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
}

.ablog__text4 {
  padding: 32px 40px 40px 40px;
  border-top: 0px;
}

.ablog__meta4 {
  margin-bottom: 20px;
}
.ablog__meta4 ul li a {
  font-size: 18px;
}
.ablog__meta4 ul li a i {
  font-size: 16px;
  color: #ff6600;
}

.ablog__text--title4 {
  font-size: 36px;
}
@media (max-width: 767px) {
  .ablog__text--title4 {
    font-size: 32px;
  }
}
.ablog__text--title4:hover {
  color: #ff6600;
}

.ablog__img4 {
  position: relative;
}
.ablog__img4 .avideo-btn-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ablog__img--active .slide-prev,
.ablog__img--active .slide-next {
  position: absolute;
  color: #ff6600;
  font-size: 20px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 3;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px;
  border-radius: 50%;
}
.ablog__img--active .slide-prev:hover,
.ablog__img--active .slide-next:hover {
  background: #ff6600;
  color: #ffffff;
}
.ablog__img--active .slide-prev {
  left: 40px;
}
@media (max-width: 767px) {
  .ablog__img--active .slide-prev {
    left: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .ablog__img--active .slide-prev {
    left: 40px;
  }
}
.ablog__img--active .slide-next {
  right: 40px;
}
@media (max-width: 767px) {
  .ablog__img--active .slide-next {
    right: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .ablog__img--active .slide-next {
    right: 40px;
  }
}

.ablog-4:hover img {
  transform: scale(1);
}

.sidebar--widget__search form {
  position: relative;
}
.sidebar--widget__search form input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0 20px;
  background: #f7f7f7;
  color: #83868c;
}
.sidebar--widget__search form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 50px;
  line-height: 60px;
  color: #ff6600;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.sidebar--widget__post {
  display: flex;
}
.sidebar--widget__post .post__img {
  height: 90px;
  width: 80px;
  border-radius: 10px;
  background-size: cover;
  background-position: left;
  margin-right: 15px;
}
.sidebar--widget__post .sidebar__post--text {
  overflow: hidden;
}

.sidebar__post--title {
  font-size: 20px;
  margin-bottom: 5px;
}
.sidebar__post--title:hover {
  color: #ff6600;
}

.sidebar__widget--title {
  font-size: 26px;
  position: relative;
  padding-bottom: 12px;
}
.sidebar__widget--title:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background: #ff6600;
}

.sidebar--widget__cat ul li {
  margin-bottom: 15px;
  list-style: none;
}
.sidebar--widget__cat ul li:last-child {
  margin-bottom: 0px;
}
.sidebar--widget__cat ul li a {
  display: inline-block;
  position: relative;
  padding-left: 20px;
}
.sidebar--widget__cat ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  color: #ff6600;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar--widget__cat ul li a:hover {
  color: #ff6600;
}
.sidebar--widget__cat ul li a:hover:before {
  left: 7px;
}

.sidebar--widget__tag a {
  display: inline-block;
  height: 34px;
  line-height: 30px;
  text-align: center;
  padding: 0 19px;
  font-size: 14px;
  border: 2px solid #dce1e4;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 10px;
}
.sidebar--widget__tag a:hover {
  background: #ff6600;
  color: #ffffff;
  border-color: #ff6600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ablog__sidebar--wrapper {
    margin-right: 0px;
  }
}

.ablog__text4 blockquote {
  padding: 40px;
  color: #666;
  position: relative;
  background: #f8f8f8;
  font-style: normal;
  text-align: left;
  clear: both;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  border: 1px solid #f6f6f8;
  margin-bottom: 30px;
  padding-bottom: 25px;
}
@media (max-width: 767px) {
  .ablog__text4 blockquote {
    padding: 25px;
    padding-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .ablog__text4 blockquote {
    padding: 40px;
    padding-bottom: 25px;
  }
}
.ablog__text4 blockquote:before {
  content: "";
  position: static;
  font-family: "Font Awesome 5 Pro";
  font-size: 32px;
  color: #ff6600;
  line-height: 1;
  margin-bottom: 18px;
  display: inline-block;
}
.ablog__text4 blockquote cite {
  font-size: 18px;
  display: block;
  margin-top: 10px;
  color: #151735;
  font-style: inherit;
}
.ablog__text4 blockquote cite:before {
  content: "";
  display: inline-block;
  background: #ff6600;
  height: 2px;
  width: 40px;
  top: -4px;
  margin-right: 10px;
  position: relative;
}

.blog__deatails--tag span {
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #151735;
}
.blog__deatails--tag a {
  display: inline-block;
  height: 34px;
  line-height: 30px;
  text-align: center;
  padding: 0 19px;
  font-size: 14px;
  border: 2px solid #dce1e4;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .blog__deatails--tag a {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__deatails--tag a {
    margin-bottom: 0px;
  }
}
.blog__deatails--tag a:hover {
  border-color: #ff6600;
  color: #ffffff;
  background: #ff6600;
}

.blog__details--subtitle {
  font-size: 27px;
  margin-bottom: 15px;
}

.blog__author {
  padding: 30px 40px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  -moz-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .blog__author {
    padding-bottom: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__author {
    padding-bottom: 30px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .blog__author-img {
    margin-bottom: 10px;
  }
}

.blog__author-content h5 {
  font-size: 18px;
  margin-bottom: 0px;
}
.blog__author-content span {
  display: inline-block;
  margin-bottom: 5px;
}
.blog__author-content p {
  margin-bottom: 0px;
}

.post-comment-title h3 {
  font-size: 24px;
}

.latest-comments ul {
  list-style: none;
}

.latest-comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}

.comments-avatar {
  float: left;
  margin-right: 20px;
}

.comments-text {
  overflow: hidden;
  border-bottom: 1px solid #f1f0f7;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.comments-text p {
  margin-bottom: 20px;
}

.avatar-name {
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.avatar-name h5 {
  font-size: 18px;
  margin-bottom: 0px;
}
.avatar-name span {
  font-size: 14px;
  font-weight: 500;
}

.comment-reply {
  font-weight: 600;
  font-size: 14px;
  color: #151735;
}
.comment-reply i {
  margin-right: 3px;
}
.comment-reply:hover {
  color: #ff6600;
}

.latest-comments li.children {
  margin-left: 105px;
}
@media (max-width: 767px) {
  .latest-comments li.children {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest-comments li.children {
    margin-left: 70px;
  }
}

.post-comment-form h4 {
  font-size: 24px;
  margin-bottom: 7px;
}

.post-comment-form > span {
  display: inline-block;
  margin-bottom: 45px;
}

.post-input input,
.post-input textarea {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid #f5f5f5;
  background: #f5f5f5;
  margin-bottom: 20px;
  color: #83868c;
}
.post-input input::-moz-placeholder,
.post-input textarea::-moz-placeholder {
  font-size: 16px;
  color: #83868c;
  opacity: 1;
}
.post-input input::placeholder,
.post-input textarea::placeholder {
  font-size: 16px;
  color: #83868c;
  opacity: 1;
}

.post-input textarea {
  height: 180px;
  line-height: 26px;
  resize: none;
  padding: 30px;
  padding-top: 20px;
}

.post-check input {
  margin-right: 5px;
}

.post-check span {
  color: #83868c;
}

@media (max-width: 767px) {
  .ablog-4 .ablog__text4 {
    padding: 17px 25px 25px 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .ablog-4 .ablog__text4 {
    padding: 32px 40px 40px 40px;
  }
}
@media (max-width: 767px) {
  .ablog-4 img {
    min-height: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .blog__details--wrapper {
    margin-right: 0px;
  }
}

/* 15. Footer CSS */
footer {
  background-position: center;
  background-repeat: repeat;
  background-color: rgb(21 32 66 / 90%);
}

.footer__widget--title {
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 25px;
  padding-bottom: 8px;
  position: relative;
}
.footer__widget--title:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 20px;
  background: #ff6600;
  left: 0px;
  bottom: 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer__widget.pl-85 {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__widget.pl-80 {
    padding-left: 40px;
  }
  .footer__widget.pl-85 {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__widget {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .footer__widget {
    padding-left: 0px;
  }
}
.footer__widget p {
  color: #bcbfc9;
}

.emg__number h5 {
  font-size: 20px;
  color: #ff6600;
  font-weight: 500;
}
.emg__number h4 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0px;
}

.widget__links li {
  list-style: none !important;
  margin-bottom: 5px;
}
.widget__links li:last-child {
  margin-bottom: 0px;
}
.widget__links li a {
  color: #bcbfc9;
  position: relative;
  text-decoration: none !important;
}
.widget__links li a:before {
  content: "_";
  left: 0px;
  bottom: 0px;
  margin-right: 5px;
}
.widget__links li a:hover {
  color: #ff6600;
}

.widget__contact li {
  list-style: none;
  margin-bottom: 5px;
  color: #bcbfc9;
}
.widget__contact li:last-child {
  margin-bottom: 0px;
}
.widget__contact li span {
  display: block;
  color: #ffffff;
  margin-bottom: 5px;
}
.widget__contact li a:hover {
  text-decoration: underline;
}

.widget__subscribe .field {
  position: relative;
}

.widget__subscribe .field input {
  height: 60px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.35);
  font-size: 16px;
  font-weight: 500;
  color: #bcbfc9;
  display: block;
  padding: 0px 20px;
  outline: none;
  border-radius: 3px;
  background: transparent;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.widget__subscribe .field input:focus {
  border-color: #ff6600;
}
.widget__subscribe button {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
}

.copyright-border {
  border-top: 1px solid rgba(255, 255, 255, 0.102);
}

.copyright__text span {
  color: #bcbfc9;
  display: block;
}
.copyright__text span a {
  color: #ff6600;
}

.copyright__social {
  padding: 18px 0px 13px 0px;
}
@media (max-width: 767px) {
  .copyright__social {
    text-align: center !important;
  }
}
.copyright__social a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 15px;
  display: inline-block;
  margin-right: 12px;
  background: #393f53;
  color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.copyright__social a:last-child {
  margin-right: 0px;
}
.copyright__social a:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0%;
  background: #ff6600;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
  transform: rotate(90deg);
}
.copyright__social a:hover {
  color: #ffffff;
}
.copyright__social a:hover i {
  transform: rotate(360deg);
}
.copyright__social a:hover:before {
  width: 100%;
}

@media (max-width: 767px) {
  .copyright__text {
    padding-top: 15px;
    text-align: center;
  }
}

.widget__links--3 li a:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
}
.widget__links--3 li a:hover {
  padding-left: 5px;
}

.widget__post--img {
  max-width: 85px;
  float: left;
  margin-right: 15px;
}

.widget__post--text {
  overflow: hidden;
}
.widget__post--text span {
  display: block;
  color: #bcbfc9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .widget__post--text span {
    font-size: 15px;
  }
}

.widget__post--text__title {
  font-size: 18px;
  color: #bcbfc9;
  font-weight: 500;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__post--text__title br {
    display: none;
  }
}
.widget__post--text__title:hover {
  color: #ff6600;
}

.insta__widget {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 270px;
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright__text--3 {
    text-align: center;
  }
}

.copyright__logo {
  padding: 18px 0px 15px 0px;
}

/* 16. Video CSS */
.video-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  min-height: 800px;
  background-image: url("../public/expert_solutions.png");
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area {
    min-height: 760px;
  }
}
.video-area:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  background: rgba(21, 32, 66, 0.9);
  z-index: -1;
}
@media (max-width: 767px) {
  .video-area {
    min-height: inherit;
  }
}

.avideo-btn {
  height: 100px;
  width: 100px;
  line-height: 100px;
  background: #ffffff;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}
.avideo-btn a {
  height: 86px;
  width: 86px;
  line-height: 86px;
  background: #ff6600;
  display: inline-block;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  transform: translate(4px, 4px);
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.avideo-btn a:hover {
  background: #2371ff;
}

@media (max-width: 767px) {
  .avideo__text {
    padding-bottom: 120px;
  }
  .avideo__text .section-title {
    font-size: 34px;
  }
}

.video-area-3 {
  min-height: inherit;
}
.video-area-3:before {
  background: rgba(21, 32, 66, 0.85);
}

@media (max-width: 767px) {
  .avideo__text--3 {
    padding-bottom: 0px;
  }
}

.play_btn {
  position: relative;
}

.play_btn:after,
.play_btn:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid #cccccc;
  animation-name: popupBtn;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.play_btn:before {
  animation-delay: 0.8s;
}

@keyframes popupBtn {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.6);
    opacity: 0.3;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
/* 17. Faq CSS */
.afaq__text {
  margin-top: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .afaq__text {
    margin-top: 0px;
  }
}
.afaq__text .accordion-item:first-of-type .accordion-button {
  border-radius: 0px;
}
.afaq__text .accordion-button:not(.collapsed) {
  color: #151735;
  font-weight: 500;
  background: transparent;
  color: #ff6600;
}
.afaq__text .accordion-button {
  border-color: #dce1e4;
  border: 0px;
  padding-top: 23px;
  padding-bottom: 23px;
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .afaq__text .accordion-button {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .afaq__text .accordion-button {
    font-size: 18px;
  }
}
.afaq__text .accordion-button:focus {
  box-shadow: none;
}
.afaq__text .accordion-item {
  margin-bottom: 20px;
  border: 1px solid #dce1e4;
  padding: 0px 10px;
}
@media (max-width: 767px) {
  .afaq__text .accordion-item {
    padding: 0px;
  }
}
.afaq__text .accordion-collapse {
  border: none;
}
.afaq__text .accordion-body {
  padding-top: 0px;
  padding-bottom: 23px;
}
.afaq__text .accordion-button::after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  background: none;
  transform: none;
  margin-right: -5px;
  font-size: 18px;
}
.afaq__text .accordion-button:not(.collapsed)::after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  color: #ff6600;
}

.afaq__img img {
  max-width: inherit;
  margin-left: -165px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .afaq__img img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .afaq__img img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .afaq__img img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .afaq__img img {
    max-width: 100%;
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .afaq__img img {
    max-width: 100%;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .faq-area-3 {
    padding-bottom: 90px;
  }
}

.afaq__img--3 {
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .afaq__img--3 {
    margin-right: 0px;
    margin-bottom: 70px;
  }
}
.afaq__img--3 img {
  position: absolute;
}
@media (max-width: 767px) {
  .afaq__img--3 img {
    position: inherit;
    width: 100%;
  }
}
.afaq__img--3 img.img-1 {
  top: 0px;
  left: 0px;
  z-index: 2;
}
.afaq__img--3 img.img-2 {
  top: 170px;
  left: 235px;
  z-index: 3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .afaq__img--3 img.img-2 {
    left: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .afaq__img--3 img.img-2 {
    left: 285px;
  }
}
@media (max-width: 767px) {
  .afaq__img--3 img.img-2 {
    display: none;
  }
}
.afaq__img--3 img.img-3 {
  margin-top: 45px;
  margin-left: 60px;
  position: inherit;
}
@media (max-width: 767px) {
  .afaq__img--3 img.img-3 {
    display: none;
  }
}
.afaq__img--3 i {
  position: absolute;
  font-size: 100px;
  color: #cecfd4;
}
@media (max-width: 767px) {
  .afaq__img--3 i {
    display: none;
  }
}
.afaq__img--3 i.icon-one {
  top: 15px;
  right: 30px;
  animation: scale-up-one infinite 5s linear;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .afaq__img--3 i.icon-one {
    right: -55px;
  }
}
.afaq__img--3 i.icon-two {
  left: 30px;
  bottom: -30px;
  animation: scale-up-one infinite 3s linear;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .afaq__img--3 i.icon-two {
    bottom: -55px;
    left: 15px;
  }
}

.afaq__text--3 {
  margin-top: 0px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .afaq__text--3 {
    margin-right: 0px;
  }
}

.afaq__banner {
  position: relative;
}
.afaq__banner img {
  width: 100%;
}

.afaq__banner--text {
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(21, 32, 66, 0.9);
  height: 100%;
  width: 100%;
  padding: 90px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .afaq__banner--text {
    padding: 15px 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .afaq__banner--text {
    padding: 90px 30px;
  }
}
.afaq__banner--text p {
  color: #ffffff;
  margin-bottom: 33px;
}

.afaq__banner--text__title {
  font-size: 48px;
  line-height: 1.25;
  color: #ffffff;
  margin-bottom: 20px;
}

/* 18. Gallery CSS */
.agallery {
  position: relative;
  overflow: hidden;
}
.agallery__img {
  border-radius: 5px;
}
.agallery__img img {
  border-radius: 5px;
}
.agallery__text {
  position: absolute;
  left: 0px;
  bottom: -100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s ease-out 0s;
  -moz-transition: all 0.7s ease-out 0s;
  -ms-transition: all 0.7s ease-out 0s;
  -o-transition: all 0.7s ease-out 0s;
  transition: all 0.7s ease-out 0s;
}
.agallery__text:before {
  position: absolute;
  content: "";
  left: 25px;
  bottom: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 15px 0 15px;
  border-color: #ffffff transparent transparent transparent;
}
.agallery:hover .agallery__text {
  opacity: 1;
  visibility: visible;
  bottom: 45px;
}

.gallery-active .slick-dots {
  bottom: -70px;
}

/* 19. Work CSS */
.work-area {
  background-repeat: no-repeat;
  background-position: top right;
}

.awork__text {
  padding: 30px 30px 27px 30px;
  border: 1px solid #dce1e4;
  border-top: 0px;
  background: #ffffff;
}

.awork__text--title {
  font-size: 27px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .awork__text--title {
    font-size: 24px;
  }
}
.awork__text--title:hover {
  color: #ff6600;
}

.awork__text--link a {
  font-weight: 600;
  position: relative;
}
.awork__text--link a:before,
.awork__text--link a:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 10px;
  background: #4f5168;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.awork__text--link a:after {
  left: auto;
  right: -20px;
}
.awork__text--link a:hover {
  color: #ff6600;
}
.awork__text--link a:hover:before,
.awork__text--link a:hover:after {
  width: 25px;
  left: -35px;
  background: #ff6600;
}
.awork__text--link a:hover:after {
  width: 25px;
  right: -35px;
  left: auto;
}

.awork__more--link span {
  display: inline-block;
}
.awork__more--link span a {
  font-weight: 600;
  color: #ff6600;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.awork__more--link span a:hover {
  text-decoration: none;
}

.awork__img {
  position: relative;
  overflow: hidden;
}
.awork__img img {
  width: 100%;
}
.awork__img--overlay {
  position: absolute;
  height: 0%;
  width: 0%;
  bottom: 0px;
  left: 0px;
  top: auto;
  right: auto;
  background: rgba(29, 40, 75, 0.5);
  visibility: hidden;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.awork__img--overlay i {
  height: 80px;
  width: 80px;
  line-height: 95px;
  text-align: center;
  display: inline-block;
  background: #ff6600;
  color: #ffffff;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .awork__img--overlay i {
    height: 60px;
    line-height: 65px;
    width: 60px;
    font-size: 28px;
  }
}

.awork:hover .awork__img--overlay {
  height: 100%;
  width: 100%;
  top: 0px;
  bottom: auto;
  left: auto;
  right: 0px;
  visibility: visible;
}
.awork:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}
.awork:hover i {
  border-radius: 10px;
}

/* 20. Feature CSS */
.afeature__text--title {
  font-size: 27px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .afeature__text--title {
    margin-bottom: 10px;
  }
}
.afeature__text--title:hover {
  color: #ff6600;
}

.afeature__icon {
  display: inline-block;
  border: 2px dashed rgba(34, 112, 255, 0.302);
  padding: 10px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.afeature__icon i {
  height: 80px;
  width: 80px;
  line-height: 87px;
  display: inline-block;
  text-align: center;
  background: #2371ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 35px;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.afeature__text p {
  margin-bottom: 0px;
  padding: 0px 25px;
}
@media (max-width: 767px) {
  .afeature__text p {
    padding: 0px;
  }
}

.afeature:hover .afeature__icon {
  border-color: #ff6600;
}
.afeature:hover .afeature__icon i {
  background: #ff6600;
  transform: rotate(360deg);
}

.about-feature-area {
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}

.choose--feature:hover i {
  animation-name: animation-pulse-shrink;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: inline-block;
}

/* 21. Brand CSS */
.brand-wrapper {
  text-align: center;
}
.brand-wrapper img {
  display: inline-block;
}

.brand-area-3 {
  position: relative;
  margin-bottom: -122px;
}
.brand-area-3:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 210px;
  background: #ff6600;
  top: 0px;
  right: 0px;
}

/* 22. Page Title CSS */


/* 22. Skill CSS */
.skill__img img {
  max-width: inherit;
  margin-left: -165px;
  border-radius: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .skill__img img {
    max-width: 100%;
    margin-left: 0px;
  }
}

.skill--title__wrapper {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skill--title__wrapper span {
  color: #151735;
}

.skill--title {
  font-size: 16px;
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
}

.skill__wrapper .progress {
  height: 10px;
  border-radius: 0px;
}
.nav-link {
  color: white;
  text-decoration: none;
  padding-bottom: 10px;
}

.nav-link-active {
  color: orange !important;
}


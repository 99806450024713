/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
  display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #0c1923;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #0c1923;
  margin-top: 44px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #383838;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  border-top: 1px solid #f1f1f1;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255, 255, 255, 0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 26px;
  height: 32px;
  padding: 12px !important;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.1);
  border: none !important;
  border-left: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: rgba(0, 0, 0, 0.9);
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.mean-remove {
  display: none !important;
}

/* mobile menu css */
.side-toggle {
  background: none;
  border: 0;
  padding: 0;
}
.side-menu-icon i {
  color: #151735;
  font-size: 32px;
  cursor: pointer;
}
.side-info {
  background: #152136;
  width: 300px;
  height: 100%;
  position: fixed;
  z-index: 9999;
  right: -100%;
  top: 0;
  padding: 30px;
  transition: 0.6s;
  overflow-y: scroll;
}
.side-info.info-open {
  right: 0;
}
.side-info-close {
  background: none;
  border: 0;
  color: #fff;
  font-size: 20px;
  padding: 0;
  margin-bottom: 20px;
}
.side-info-content h1 {
  color: #fff;
  font-size: 29px;
}
.offcanvas-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}
.offcanvas-overlay.overlay-open {
  opacity: 0.5;
  visibility: visible;
}
/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}
.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}
.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}
.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}
.mean-container a.meanmenu-reveal {
  display: none !important;
}
.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  border-top: 1px solid #2a3a57;
  font-size: 15px;
}
.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 0px;
  height: 15px;
  padding: 15px 12px !important;
  line-height: 14px;
}
/* .mean-container .mean-nav > ul > li:first-child a{
	border-top: 0;
} */

.page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .content {
    max-width: 500px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .content h1 {
    font-size: 72px;
    margin-bottom: 10px;
    color: #ff4d4d;
  }
  
  .content h2 {
    font-size: 36px;
   
  }
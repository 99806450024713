.service-tabs {
    display: flex;
  }
  
  .sidebar {
    padding: 35px 30px 40px 30px;
    background-color: #f7f7f7;
    height: auto;
    margin-bottom: 50px;
    /* position: sticky; */
    top: 100px;
    font-size: 20px;
  }

  .content{
    position: relative !important;
  }

 
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    list-style: none;
    padding: 21px 25px 19px 25px;
    background: #ffffff;
    margin-bottom: 12px;
    line-height: 1;
    cursor: pointer;
  }
  
  .sidebar li:hover {
    background-color: #ddd;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
    position: relative;
  }
  
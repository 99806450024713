/* styles/ScrollToTopButton.module.css */
.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    z-index: 999999;
  }
  
  .scrollToTopButton:hover {
    background-color: #ff6600;
  }
  
  .hidden {
    display: none;
  }
  
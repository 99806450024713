.acontact__img img {
    margin-left: -165px;
    min-height: 715px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .acontact__img img {
      min-height: inherit;
    }
  }
  @media (max-width: 767px) {
    .acontact__img img {
      margin-left: 0px;
      min-height: inherit;
    }
  }
  
  .acontact__form {
    background: #ff6600;
    padding: 40px 50px 50px 50px;
  }
  @media (max-width: 767px) {
    .acontact__form {
      padding: 20px 30px 30px 30px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .acontact__form.mr-70 {
      margin-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px),
    (max-width: 767px) {
    .acontact__form.mr-70 {
      margin-right: 0px;
      margin-bottom: 50px;
    }
  }
  .acontact__form input,
  .acontact__form textarea,
  .acontact__form select {
    height: 60px;
    width: 100%;
    display: block;
    font-size: 16px;
    color: #83868c;
    padding: 0px 20px;
    border-radius: 3px;
    background: #ffffff;
    margin-bottom: 20px;
    border: none;
    outline: none;
    font-family: "Saira Semi Condensed", sans-serif;
    font-weight: 500;
  }
  .acontact__form input::-moz-placeholder,
  .acontact__form textarea::-moz-placeholder,
  .acontact__form select::-moz-placeholder {
    color: #83868c;
    opacity: 1;
  }
  .acontact__form input::placeholder,
  .acontact__form textarea::placeholder,
  .acontact__form select::placeholder {
    color: #83868c;
    opacity: 1;
  }
  .acontact__form textarea {
    height: 110px;
    padding: 20px;
  }
  .acontact__form button {
    height: 60px;
    width: 100%;
    display: block;
    background: #151735;
    color: #ffffff;
    line-height: 60px;
    border: none;
    outline: none;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 3px;
    font-weight: 600;
  }
  .acontact__form button:hover {
    background: #2371ff;
  }
  
  .select__field--arrow {
    position: relative;
  }
  .select__field--arrow:after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 15px;
    color: #83868c;
    font-family: "Font Awesome 5 Pro";
    z-index: 2;
  }
  
  .acontact__form--title {
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    .acontact__form--title {
      margin-bottom: 20px;
    }
  }
  
  .acontact__img {
    position: relative;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .acontact__img {
      margin-bottom: 60px;
    }
  }
  .acontact__img--text {
    padding: 100px 20px 20px 40px;
    height: 320px;
    width: 340px;
    position: absolute;
    right: 0px;
    bottom: 100px;
  }
  @media (max-width: 767px) {
    .acontact__img--text {
      position: inherit;
      padding-left: 0px;
      height: inherit;
      width: inherit;
    }
  }
  .acontact__img--text h2 {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 0px;
  }
  .acontact__img--text h2 span {
    color: #ff6600;
  }
  .acontact__img--text__backtitle {
    font-size: 290px;
    color: #f3f6f9;
    display: inline-block;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 0.299;
    z-index: 1;
    -webkit-text-fill-color: white;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #f3f6f9;
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    .acontact__img--text__backtitle {
      font-size: 200px;
      left: inherit;
      transform: inherit;
      opacity: 0;
    }
  }
  
  .contact__inner {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px),
    only screen and (min-width: 576px) and (max-width: 767px),
    (max-width: 767px) {
    .contact__inner {
      display: inherit;
    }
  }
  
  .contact__inner--img {
    flex-basis: 40%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact__inner--img {
      flex-basis: 45%;
    }
  }
  .contact__inner--img .c-img {
    width: 100%;
  }
  
  .contact__inner--form {
    flex-basis: 60%;
    padding: 86px 80px 88px 80px;
    box-shadow: 0px 0px 20px 0px rgba(0, 6, 91, 0.05);
    background: #ffffff;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .contact__inner--form {
      padding: 71px 80px 73px 80px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact__inner--form {
      flex-basis: 55%;
      padding: 62px 50px 62px 50px;
    }
  }
  @media (max-width: 767px) {
    .contact__inner--form {
      padding: 50px 30px;
    }
  }
  
  .contact__inner--img__number {
    text-align: center;
    padding: 40px 30px;
    margin: 0px 1px;
    margin-top: -1px;
    position: relative;
  }
  .contact__inner--img__number a {
    border: 5px solid #ffd7bd;
    line-height: 70px;
    font-size: 24px;
    padding: 0px 35px;
  }
  @media (max-width: 767px) {
    .contact__inner--img__number a {
      font-size: 18px;
    }
  }
  .contact__inner--img__number .news__service--number__icon {
    border-color: #bcd4ff;
    background: #2371ff;
  }
  
  .map-area {
    height: 700px;
    width: 100%;
    margin-bottom: 0px;
    position: relative;
  }
  .map-area iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
  
  .contact-newsletter-area {
    position: absolute;
    bottom: -90px;
    left: 0px;
    width: 100%;
    z-index: 2;
    margin: 0px;
  }
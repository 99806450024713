.page-title{
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .page-title:before {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(21, 32, 66, 0.9);
    z-index: -1;
  }
  
  .page-title .breadcrumb-title {
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    color: #ffffff;
    text-transform: uppercase;
  }
  @media (max-width: 767px) {
    .page-title .breadcrumb-title {
      font-size: 48px;
    }
  }
  .page-title .trail-items {
    padding: 18px 45px;
    background: #ff6600;
    display: inline-block;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  @media (max-width: 767px) {
    .page-title .trail-items {
      padding: 12px 30px;
    }
  }
  .page-title .trail-items .trail-item {
    display: inline-block;
  }
  .page-title .trail-items .trail-item a,
  .page-title .trail-items .trail-item span {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }
  .page-title .trail-items .trail-item a {
    padding-right: 10px;
    position: relative;
  }
  .page-title .trail-items .trail-item a:before {
   
  }
  .page-title .trail-items .trail-item a:hover {
    color: #151735;
  }
  
  .page-title-icon {
    position: relative;
  }
  .page-title-icon i {
    position: absolute;
    font-size: 48px;
    color: rgba(255, 255, 255, 0.3);
  }
  .page-title-icon i.hero__icon1 {
    top: -150px;
    left: 125px;
    animation: scale-up-one infinite 3s linear;
  }
  .page-title-icon i.hero__icon3 {
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    animation: scale-up-one infinite 5s linear;
  }
  .page-title-icon i.hero__icon2 {
    bottom: -160px;
    left: -70px;
    animation: scale-up-one infinite 7s linear;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px),
    only screen and (min-width: 768px) and (max-width: 991px),
    (max-width: 767px) {
    .page-title-icon i.hero__icon2 {
      padding-left: 60px;
    }
  }
  
  .breadcrumb-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    bottom: 0px;
  }
.aslider--video__wrapper {
  display: inline-flex;
  align-items: center;
}
.aslider--btn a {
  text-decoration: none !important;
}

.aslider--video__wrapper .aslider--video__icon {
  font-size: 13px;
  height: 80px;
  width: 80px;
  border-radius: 5px;
  border: 7px solid #ffe0cc;
  line-height: 65px;
  text-align: center;
  background: #ff6600;
  border-radius: 5px;
  display: inline-block;
  color: #ffffff;
}

.aslider--video__title {
  font-size: 24px;
  margin-left: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}

.aslider--title {
  font-size: 80px;
  line-height: 1.12;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
}
@media (max-width: 767px) {
  .aslider--title {
    font-size: 48px;
  }
}

.aslider--subtitle {
  font-size: 30px;
  line-height: 1.12;
  font-weight: 500;
  color: white;
}
@media (max-width: 767px) {
  .aslider--subtitle {
    font-size: 26px;
  }
}

.single-slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.single-slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(21, 32, 66, 0.6);
  z-index: 1;
}

.aslider {
  position: relative;
  z-index: 2; /* Ensures text is above the overlay */
}

.slider-height {
  min-height: 550px;
  background-color: #151735;
}

.slide-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: scale(1);
  -webkit-transition: all 8s ease-out 0s;
  -moz-transition: all 8s ease-out 0s;
  -ms-transition: all 8s ease-out 0s;
  -o-transition: all 8s ease-out 0s;
  transition: all 8s ease-out 0s;
}

.swiper-slide-active .slide-bg {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  transform: scale(1.12);
}

.slider-active .slide-prev,
.slider-active .slide-next {
  position: absolute;
  font-size: 20px;
  background: #ffffff;
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 20px;
  color: #151735;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 3;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  margin: 0px;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 0px 30px 0px rgba(0, 5, 63, 0.1);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-active .slide-prev,
  .slider-active .slide-next {
    top: inherit;
    bottom: 15%;
    left: 44.5%;
    transform: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-active .slide-prev,
  .slider-active .slide-next {
    left: 44%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active .slide-prev,
  .slider-active .slide-next {
    left: 42.5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active .slide-prev,
  .slider-active .slide-next {
    left: 40.5%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-active .slide-prev,
  .slider-active .slide-next {
    left: 37.5%;
  }
}
@media (max-width: 767px) {
  .slider-active .slide-prev,
  .slider-active .slide-next {
    display: none;
  }
}
.slider-active .slide-prev:hover,
.slider-active .slide-next:hover {
  background: #ff6600;
  color: #ffffff;
}

.slider-active .slide-next {
  left: auto;
  right: 40px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-active .slide-next {
    top: inherit;
    bottom: 15%;
    right: 44.5%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-active .slide-next {
    right: 44%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-active .slide-next {
    right: 42.5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-active .slide-next {
    right: 40.5%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-active .slide-next {
    right: 37.5%;
  }
}
@media (max-width: 767px) {
  .slider-active .slide-next {
    display: none;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.7;
}

.hero-area-2 {
  background-position: top right;
  background-repeat: no-repeat;
  min-height: 900px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .hero-area-2 {
    background-size: cover;
  }
  .hero-area-2:before {
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.6);
  }
}
@media (max-width: 767px) {
  .hero-area-2 {
    min-height: 820px;
  }
}

.hero__text--2 {
  position: relative;
}
.hero__text--2 .hero__icon {
  position: absolute;
  color: #d5d7db;
  font-size: 56px;
  animation-name: scale-up-one;
  animation-delay: 0s;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .hero__text--2 .hero__icon {
    color: #666;
  }
}
.hero__text--2 .hero__icon1 {
  left: -180px;
  top: 125px;
  animation: scale-up-one infinite 4s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .hero__text--2 .hero__icon1 {
    left: 50px;
    top: 30px;
  }
}
.hero__text--2 .hero__icon2 {
  left: 400px;
  top: 60px;
  animation: scale-up-one infinite 3s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .hero__text--2 .hero__icon2 {
    left: inherit;
    right: 30px;
    top: 100px;
  }
}
.hero__text--2 .hero__icon3 {
  left: 340px;
  bottom: -65px;
  animation: scale-up-one infinite 6s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .hero__text--2 .hero__icon3 {
    left: inherit;
    right: 20px;
    bottom: 30px;
  }
}

.slider__icon {
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  font-size: 60px;
  animation-name: scale-up-one;
  animation-delay: 0s;
  z-index: 2;
}
.slider__icon.slider__icon1 {
  left: 180px;
  top: 230px;
  animation: scale-up-one infinite 4s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__icon.slider__icon1 {
    left: 100px;
    top: 120px;
  }
}
@media (max-width: 767px) {
  .slider__icon.slider__icon1 {
    top: 90px;
    left: 30px;
  }
}
.slider__icon.slider__icon2 {
  left: 270px;
  bottom: 200px;
  animation: scale-up-one infinite 3s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__icon.slider__icon2 {
    bottom: 100px;
    left: 40%;
  }
}
@media (max-width: 767px) {
  .slider__icon.slider__icon2 {
    display: none;
  }
}
.slider__icon.slider__icon3 {
  top: 110px;
  left: 35%;
  animation: scale-up-one infinite 6s linear;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__icon.slider__icon3 {
    top: 150px;
    right: 100px;
    left: auto;
  }
}
@media (max-width: 767px) {
  .slider__icon.slider__icon3 {
    top: 100px;
    right: 30px;
    left: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .aslider-3 {
    padding-top: 200px;
  }
}

.aslider--title__3 {
  font-size: 90px;
  line-height: 1;
  text-transform: inherit;
  font-weight: 700;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aslider--title__3 {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .aslider--title__3 {
    font-size: 48px;
  }
}

.aslider--date {
  font-size: 27px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 25px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.aslider--date span {
  color: #ff6600;
}

.aslider--subtitle__3 {
  font-weight: 400;
}

.single-slider-3 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.single-slider-3 .slide-bg:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(4, 14, 46, 0.8);
}

.slider-height-3 {
  min-height: 885px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .slider-height-3 {
    min-height: 800px;
  }
}

.slider-active.swiper-container-horizontal.common-dots .slide-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  z-index: 5;
  width: 20px;
  left: auto;
  bottom: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .slider-active.swiper-container-horizontal.common-dots .slide-dots {
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    top: inherit;
    right: inherit;
    width: inherit;
  }
}
.slider-active.swiper-container-horizontal.common-dots
  .slide-dots
  .swiper-pagination-bullet {
  font-size: 0px;
  height: 6px;
  width: 6px;
  padding: 3px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  display: inline-block;
  outline: none;
  position: relative;
  margin: 8px;
}
.slider-active.swiper-container-horizontal.common-dots
  .slide-dots
  .swiper-pagination-bullet:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 3px solid transparent;
  top: -6px;
  left: -6px;
}
.slider-active.swiper-container-horizontal.common-dots
  .slide-dots
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  border-color: #ffffff;
}

.swiper-slide-active .slide-bg {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .swiper-slide-active .slide-bg {
    background-position: left;
  }
}

.slide-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.slide-shape img {
  margin-bottom: -5px;
}

.single-slider-3 .slide-bg {
  filter: grayscale(100%);
}

.slide-bg-pt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  background-repeat: repeat;
}

.single-slider-3 .slide-bg::before {
  display: none;
}

.slider__icon {
  z-index: 3;
}
